// @import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/materialize-css/sass/components/color-variables.scss';

$primary-color: #f5f5f5 !default;
$secondary-color: #4B7DC2 !default;

@import '../../node_modules/materialize-css/sass/materialize.scss';

body {
    font-family: Roboto,"Nunito Sans",sans-serif;
    line-height: 1.5;
    letter-spacing: .005em;
    transition: padding-top .4s;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
}

header, main, footer {
    padding-left: 300px;

    @media only screen and (max-width: 992px) {
        padding-left: 0;
    }
}

.container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
  }
  @media #{$medium-and-up} {
    .container {
      width: 90%;
    }
  }
  @media #{$large-and-up} {
    .container {
      width: 90%;
    }
  }

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
    &.input-validation-error {
        @extend %invalid-input-style;
    }
}

.text-danger {
    color: #F44336;
}

.input-field {
    margin: .4rem 0;
}

.input-field .helper-text.field-validation-valid {
    display: none;

    &.input-validation-error {
        display: block;
    }
}

.back-to-index {
    margin: 1rem 0;

    &:last-child {
        margin: 2rem 0;
    }
}

.no-margin {
    margin: 0;
}

.no-margin-top {
    margin-top: 0;
}

.checkbox-field {
    margin: 1rem 0 2rem 0;

    &.small {
        margin: 1rem 0 1rem 0;
    }
}

$navBarTextColor: #3e3e3e;

.brand-logo {
    img {
        margin-top: .5rem;
        width: 40px;
        height: 40px;
    }

    color: $navBarTextColor;
    display: flex;
    align-content: center;
}

nav {
    .nav-wrapper {
        .brand-logo {
            img {
                margin-left: .7rem;
                margin-right: .7rem;
            }

            @media only screen and (min-width: 992px) {
                &::after {
                    content: "Arragro Support"
                }
            }
        }

        ul a {
            color: $navBarTextColor;
        }

        .sidenav-trigger {
            i {
                color: $navBarTextColor;
            }
        }
    }

    @media only screen and (min-width: 992px) {
        display: none;
    }
}

.sidenav li > a.brand-logo {
    height: 130px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #dcdcdc;

    img {
        margin-top: .5rem;
        margin-right: .7rem;
        width: 100px;
        height: 100px;
    }
}

.main-container {
    // overflow: auto;
}

.helper-text.field-validation-error {
    color: #F44336;
}

.crud {
    margin-top: 2rem;
}

.account-card {
    margin-top: 3rem;

    > .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .col {
            margin-left: initial;
        }

        .card {
            padding: 1rem;

            .full-width {
                margin: 1rem 0;
            }
        }

        .btn {
            width: 100%;
            overflow: initial;
            height: auto;
            line-height: 20px;
            padding: 6px;

        }
    }
}

.microsoft-btn {
    display: flex;
    align-content: center;
    justify-content: center;

    img {
        width: 15px;
        margin-right: 5px;
    }
}

#loginWithAccount {
    margin-top: 1.5rem;
}

#arragrologo {
    width: 50%;
}

table.row-hover-pointer tbody tr:hover {
    cursor: pointer;
}

.attachment-name {
    word-break: break-word;
}

.comments {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .card.comment {
        width: 100%;

        @media #{$small-and-down} {
            font-size: 12px;
        }

        .header {
            padding: 1rem 1rem;
            margin: 0;
            @extend .grey, .lighten-4;
            border-bottom: 1px solid #eeeeee;

            .email {
                word-break: break-all;
            }
        }
    }

    .system {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 80%;
        font-style: italic;

        .tag {
            width: 20%;

            span {
                display: inline-block;
                padding: .2rem;
                background-color: #ff6a00;
                color: #f5f5f5;
            }
        }

        .details {
            width: 70%;
        }
    }

    .admin-hide {
        display: none;
    }

    .admin-show {
        display: flex;
    }
}

#CommentForm {
    .comment-submit-buttons {
        .btn {
            margin-bottom: 5px;
        }
    }

    .ticket-resolved {
        margin-left: 5px;
    }
}

.file-upload-wrapper {
    display: flex;
    flex-wrap: wrap;

    .file-field {
        flex-grow: 2;

        .file-path-wrapper {
            input.file-path {
                width: 98%;
            }
        }
    }
}

.readonly-field {
    position: relative;
    margin: 1rem 0;
    float: left;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px;
    width: 100%;

    &:first-child {
        margin-top: 1.2rem;
    }

    .value {
        font-size: initial;
        color: initial;
    }

    .label {
        left: 0.75rem;
        position: absolute;
        top: -26px;
        font-size: 0.8rem;
    }
}
pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: Roboto,"Nunito Sans",sans-serif;
    word-break: break-word;
}

.comments {
    #loadMoreContainer {
        width: 100%;

        .hr {
            height: 100%;
            display: flex;
            align-items: center;

            & > hr {
                width: 100%;
            }
        }

        #loadMore {
            p {
                margin: 1rem 0;
            }
        }
    }
}

